*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Arial", sans-serif !important;
  color: var(--color);
  background-color: var(--background-color);

  pre {
    color: var(--color);
  }
}

.eshava-text-primary-hover:hover {
  color: var(--primary);

  &.eshava-core-svg,
  .eshava-core-svg {
    svg {
      fill: var(--primary);
    }
  }
}

.eshava-text-center {
  text-align: center;
}

.eshava-text-right {
  text-align: right;

  &.eshava-grid-sort-and-search {
    padding-right: 2.5rem;
  }

  &.eshava-grid-sort,
  &.eshava-grid-search {
    padding-right: 1.5rem;
  }
}

.eshava-grid-header-right {
  justify-content: right !important;

  span {
    margin-right: 0.5rem;
  }
}

.eshava-modal-control-row {
  display: flex;
  flex-direction: row;
  gap: 1rem;

  >* {
    flex: 1 1;
  }
}

.card-headline {
  font-size: var(--card-font-size-headline);
  font-weight: 500;
  color: var(--card-color-headline);
  margin-bottom: 0.75rem;
  display: flex;
}

.cards {
  padding: 0.5625rem;
  row-gap: 0.75rem;
  background-color: var(--card-background-color);
}

.data-label {
  min-height: 0.88rem;
  font-size: var(--card-font-size-primary);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: var(--card-color-primary);
}

.data-value {
  min-height: 0.88rem;
  font-size: var(--card-font-size-secondary);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: var(--card-color-secondary);

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.pointer {
  cursor: pointer;
}

/* margin left*/
.ms-auto {
  margin-left: auto !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

/* margin right */
.me-auto {
  margin-right: auto !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

/* marging top */
.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

/* margin bottom */
.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

/* padding left*/
.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

/* padding right */
.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

/* padding top */
.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

/* padding bottom */
.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.with-border {
  border: solid 0.063rem var(--eshava-controls-input-border-color);
  padding: 0.5rem;
}

.eshava-modal-control-row {
  display: flex;
  flex-direction: row;
  gap: 1rem;

  >* {
    flex: 1 1;
  }
}

h1,
.h1 {
  font-size: 2.5rem;
}

h2,
.h2 {
  font-size: 2.0rem;
}

h3,
.h3 {
  font-size: 1.75rem;
}

h4,
.h4 {
  font-size: 1.5rem;
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1.0rem;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
