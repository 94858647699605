@import "../../../../../node_modules/eshava-grid/eshava-grid.scss";
@import "../../../../../node_modules/eshava-controls/eshava-controls.scss";

@import "./base";
@import "./button";
@import "./eshava-grid-custom";
@import "./eshava-controls-custom";

.eshava-core-svg {
  svg {
    height: 1rem;
    width: 1rem;
    fill: var(--color);
  }
}
