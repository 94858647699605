.sidebar-controls {

  .eshava-controls-input,
  select {

    background-color: var(--background-color) !important;
  }
}

.eshava-trash {
  .eshava-controls-svg {
    svg {
      cursor: pointer;
      fill: var(--red) !important;
    }
  }
}